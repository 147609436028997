import React from "react"
import { Layout } from "../components/Layout"
import {
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner,
  IntegrationsSection,
  TryIrisGPTForFreeBanner,
  FeatureSection,
  FeatureSectionRight,
} from "../commonComponents"
import {
  IntegrationDarkSection
} from "../integrationsUtils"
import { Helmet } from "react-helmet"

export default function UnifiedSearch() {
  return (
    <Layout
      keywords="unified search for support, ai search for enterprise, ai search for customer support, enterprise ai search"
      title="AI-Powered Unified Search for Self-Service | IrisAgent"
      description="IrisAgent's enterprise AI search for self-service helps customers find answers to their queries faster. It leverages semantic search to search in structured and unstructured data."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/unified-search/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span className="text-highlight">Enterprise AI Search:</span> Unified Search to self-serve customers using GenAI
            </h1>
            <div className="description-support-new-design web-view-new-design-display">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "20px" }}> Find instant answers across knowledge base, FAQs, <br/>product documentation, and more
                </li>
              <li style={{ paddingLeft: "20px" }}>
               Transform customer digital experiences for self-service<br/> support
                </li>
                <li style={{ paddingLeft: "20px" }}>
               Discover gaps in knowledge base and FAQs
                </li>
            </ul>
            </div>

            <div className="description-support-new-design mobile-view-new-design-display">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "20px" }}> Find instant answers across knowledge <br/>base, FAQs, product documentation, <br/>and more
                </li>
              <li style={{ paddingLeft: "20px" }}>
               Transform customer digital experiences <br/>for self-service support
                </li>
                <li style={{ paddingLeft: "20px" }}>
               Discover gaps in knowledge base <br/>and FAQs
                </li>
            </ul>
            </div>

            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6 centered-image-wrapper" >
              <img
                src="/img/answerFaster.webp"
                alt="support agent"
                style={{ width: "70%"}}
              />
            </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}
      <br/><br/>
     <FeatureSection
            title="Intelligent AI search for deflection on help center and community pages"
            description=<span>
                  <ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    Eliminate Information Silos With Unified Search. Connect with knowledge bases, ticket data, FAQs, product documentation, engineering updates, and more.
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Highly relevant and actionable answers using LLM vector search, which processes search queries to provide precise and relevant information.
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Agent and Customer facing Search with configurable knowledge bases
                    </li>
                  </ul>
                  </span>
            imageUrl="/img/allIntegrationsMain.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />

      <FeatureSectionRight
            title="Rich insights on gaps in knowledge base and community content"
            description=<span>
                    <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}>
                    Identify gaps in knowledge base and community content
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Identify popular topics with automated intent recognition
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Discover top performing articles and content
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Data analysis and knowledge graph to identify gaps and popular topics, enhancing the insights provided by Unified Search
                    </li>
                    </ul>
                    </span>

            imageUrl="/img/homeIlustration1.webp"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />
      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

            {/* <!-- ======= Integrations Section ======= --> */}
      <IntegrationsSection />
      {/* <!-- End Integrations Section --> */}


             <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is Federated Unified Enterprise Search?"
                  answer="Unified Enterprise Search is a comprehensive search engine solution designed for large organizations to streamline their search functionalities across various platforms and data sources within their enterprise ecosystem. Understanding the distinction between federated and unified search is crucial; federated search operates by searching each source individually and then merging the results for the user, whereas unified search conducts a simultaneous search across all sources, offering a more efficient and faster method for locating matching information, thereby providing a superior solution in our fast-paced, technologically advanced environment.

Generative AI further enhances this process by providing multi-turn, multimodal, and immersive search experiences."
                />
                <Faq
                  id="faq2"
                  question="How does AI Powered Unified Enterprise Search work?"
                  answer="Using advanced artificial intelligence (AI) and machine learning algorithms, Enterprise AI Search indexes and organizes structured and unstructured data from disparate sources such as CRMs, ticketing platforms, databases, cloud storage, and internal documents. It creates a unified index, a central repository of all the data indexed by the search engine, allowing for a more efficient and accurate search experience. It then provides users with a single interface to search and access this information quickly and efficiently, eliminating the need to navigate multiple systems. This unified search approach enhances efficiency and user experience by combining information from multiple systems into a single search interface.

Our approach to AI is grounded in the principles of responsible AI, ensuring ethical and scalable solutions for business contexts."
                />
                <Faq
                  id="faq3"
                  question="What are the benefits of using Unified Enterprise Search for an enhanced search experience?"
                  answer="Unified Enterprise Search enhances productivity by reducing the time spent searching for information. It improves decision-making by providing users with comprehensive and relevant search results. Additionally, it promotes self-service among customers and employees, leading to increased satisfaction and efficiency. By offering a faster, more efficient, and comprehensive search experience, Unified Enterprise Search significantly improves the quality of search experiences by eliminating the need to navigate multiple systems or search engines."
                />
                <Faq
                  id="faq4"
                  question="Can Unified Enterprise AI Search integrate with existing systems?"
                  answer="Yes, Unified Enterprise Search is designed to seamlessly integrate with various enterprise systems, including customer support platforms like Zendesk and Salesforce, customer relationship management (CRM) software, enterprise resource planning (ERP) systems, content management systems (CMS), and more. It surfaces relevant results from these siloed platforms that might be hard to find otherwise."
                />
                <Faq
                  id="faq5"
                  question="Is Enterprise Search customizable to suit our organization's specific needs?"
                  answer="Absolutely. Enterprise Search Engines can be customized and configured to align with your organization's unique requirements, including branding, security protocols, and data sources. These search engines also leverages search history and search results to find unique patterns and gaps in your knowledge base."
                />
                <Faq
                  id="faq6"
                  question="How does AI enhance Enterprise Search?"
                  answer="AI technologies in enterprise search engines, including natural language processing (NLP) and machine learning, are pivotal in enhancing the search process. They enable a more efficient and effective search mechanism by understanding user queries better, improving search relevancy, and providing personalized recommendations based on user behavior and preferences. This streamlined search process, facilitated by AI, allows users to search for information from multiple sources at once, significantly improving the user experience in Unified Enterprise Search."
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
